import * as React from 'react';
import s from './OfferingMoreInfoLabel.st.css';
import { TextButton } from 'wix-ui-tpa/TextButton';
import {
  RunningEnvironmentProps,
  withRunningEnvironmentContext,
} from '../../context/running-environment-context';

export interface OfferingMoreInfoLabelProps extends RunningEnvironmentProps {
  onClick?: Function;
  children?: any;
}

const OfferingMoreInfoLabelComponent = (props: OfferingMoreInfoLabelProps) => {
  const { onClick, children, runningEnvironment } = props;
  const { isMobile } = runningEnvironment;
  return (
    <div className={s.textButtonWrapper}>
      <TextButton
        onClick={onClick}
        {...s('root', { disableFontSize: isMobile, isMobile }, props)}
      >
        {children}
      </TextButton>
    </div>
  );
};

export const OfferingMoreInfoLabel = withRunningEnvironmentContext(
  OfferingMoreInfoLabelComponent,
);
